<script setup lang="ts">
import { UserRolesEnum } from '~/utils/enums/userRoles'

const languageStore = useLanguageStore()
const { updateUserLanguage, fetchLanguages } = languageStore
const { languages, interfaceLanguage } = storeToRefs(languageStore)
const { toggleMenu } = useMenuStore()
const { isMenuCollapsed } = storeToRefs(useMenuStore())
const { showAdminInterface, userRoles } = storeToRefs(useAuthStore())

const fetching = ref(false)
const posting = ref(false)

const { locales } = useI18n()
const matchedLocales = computed(() => {
  if (languages.value.length === 0) {
    return locales.value
  }
  const updatedLocales = locales.value
    .filter(lang => languages.value.some(l => l.tag === lang.iso))
    .sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)
  updatedLocales.unshift(locales.value[0])
  return updatedLocales
})

async function setLanguage(locale?: Language) {
  if (!locale) {
    return
  }
  interfaceLanguage.value = locale
  const language = languages.value.find(
    lang => lang.tag?.toLocaleLowerCase() === locale.iso.toLocaleLowerCase(),
  )

  const languageId = language?.id
  await updateUserLanguage(posting, languageId)
}

// get the country code from the locale iso code
function getCountryCode(locale?: string) {
  if (locale) {
    return locale.split('-')[1]
  }
}

function switchBetweenAdminAndAdvisor() {
  if (showAdminInterface.value) {
    navigateTo('/projects')
  }
  else {
    navigateTo('/admin')
  }
  showAdminInterface.value = !showAdminInterface.value
}

onMounted(() => {
  fetchLanguages(fetching)
})
</script>

<template>
  <div
    class="font-secu-body-bold z-[100] flex cursor-auto items-center justify-between bg-primary-navyblue-850 pr-spacing-lg">
    <div class="flex h-full gap-spacing-sm">
      <button class="pl-spacing-sm sm:hidden" @click="toggleMenu">
        <SvgoHamburger class="size-6" fill="white" />
      </button>
      <div
        class="flex w-20 cursor-default items-center gap-spacing-sm bg-primary-navyblue-900 p-spacing-sm text-white transition-transform">
        <div class="flex gap-spacing-sm">
          <SvgoAdvisor />
        </div>
        <button
          class="hidden items-center rounded-md bg-primary-navyblue-700 p-spacing-xs sm:flex"
          @click="toggleMenu">
          <SvgoArrowRight v-if="isMenuCollapsed" class="size-6 fill-white" />
          <SvgoArrowLeft v-else class="size-6 fill-white" />
        </button>
      </div>
      <div
        class="fixed left-0 top-0 z-50 hidden w-64 cursor-default items-center justify-between gap-spacing-sm bg-primary-navyblue-900 p-spacing-sm text-white transition-transform duration-300 sm:flex"
        :class="{
          'translate-x-0': !isMenuCollapsed,
          '-translate-x-full': isMenuCollapsed,
        }">
        <div class="flex gap-spacing-sm">
          <SvgoAdvisor /><span>Advisor</span>
        </div>
        <button
          class="hidden items-center rounded-md bg-primary-navyblue-700 p-spacing-xs sm:flex"
          @click="toggleMenu">
          <SvgoArrowLeft class="size-6 fill-white" />
        </button>
      </div>
    </div>
    <div class="flex items-center gap-spacing-sm">
      <SButton
        v-if="userRoles.includes(UserRolesEnum.GLOBAL_ADMIN)"
        size="xs"
        :text="showAdminInterface ? $t('switch_advisor') : $t('switch_admin')"
        class="hidden h-9 border-2 border-primary-navyblue-100 text-primary-navyblue-100 sm:flex"
        @click="switchBetweenAdminAndAdvisor" />
      <div class="dropdown dropdown-end">
        <div
          v-if="interfaceLanguage?.name"
          class="secu-body-small-bold flex cursor-pointer items-center gap-spacing-sm px-spacing-md py-spacing-xs text-white"
          tabindex="0">
          <LanguageItem
            :language="interfaceLanguage?.name"
            :country-code="getCountryCode(interfaceLanguage?.iso)" />
          <SvgoArrowDown class="size-4 fill-white" />
        </div>
        <ul
          tabindex="0"
          class="secu-body-small menu dropdown-content z-[1] w-fit rounded-lg border-2 border-accent-purple-400 bg-white p-spacing-md shadow">
          <li
            v-for="locale in matchedLocales"
            :key="locale.iso"
            @click="setLanguage(locale as Language)">
            <a class="whitespace-nowrap p-spacing-sm">
              <LanguageItem
                v-if="locale.name"
                :language="locale.name"
                :country-code="getCountryCode(locale.iso)" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
